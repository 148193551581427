import React from 'react';

import { Layout } from '../components/layouts';
import { SEO } from '../components/common';
import {
  FreeTrialHeroSection,
  FreeTrialContentSection,
} from '../components/sections';

const FreeTrial: React.FC = () => (
  <Layout>
    <SEO
      title="Understanding the Woosa Sleep Trial - 30 nights ✔️"
      titleTemplate=""
      meta={[
        {
          name: 'keywords',
          content:
            'free mattress trial no payment, mattress sleep trial, trial mattress',
        },
      ]}
    />
    <FreeTrialHeroSection />
    <FreeTrialContentSection />
  </Layout>
);

export default FreeTrial;
